<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';
import { OrderBox } from '#types/order';
import { computed, useTracker } from '#imports';

interface IProps {
  code: string;
  path?: RouteLocationRaw | undefined;
  isMobile?: boolean;
}

const props = defineProps<IProps>();

const { jitsuEvent, gtagEvent } = useTracker();

const manageOrderList = {
  change_flight: {
    title: 'global.aftersalestitle1',
    subtitle: 'global.aftersalesdesc1',
    imgSource: '/img/illustration/v2/spot/change-flight.png',
    event: 'homepage-changeflight-click',
    params: 'change-flight',
    analytic: 'homepage_changeflight_click',
  },
  add_baggage: {
    title: 'global.aftersalestitle2',
    subtitle: 'global.aftersalesdesc2',
    imgSource: '/img/illustration/v2/spot/add-baggage.png',
    event: 'homepage-addbaggage-click',
    params: 'add-baggage',
    analytic: 'homepage_addbaggage_click',
  },
  refund: {
    title: 'global.aftersalestitle3',
    subtitle: 'global.aftersalesdesc3',
    imgSource: '/img/illustration/v2/spot/refund.png',
    event: 'homepage-refund-click',
    params: 'refund-request',
    analytic: 'homepage_refund_click',
  },
  order_list: {
    title: 'global.aftersalestitle4',
    subtitle: 'global.aftersalesdesc4',
    imgSource: '/img/icon/order.png',
    analytic: '',
    event: '',
    params: '',
  },
  special_request: {
    title: 'bookingform.addspecialrequest',
    subtitle: 'hotel.addspecialreqdesc',
    imgSource: '/img/illustration/v2/spot/special-request.png',
    event: '',
    analytic: '',
    params: '',
  },
  booking_date: {
    title: 'hotel.changedatehoteltitle',
    subtitle: 'hotel.changehoteldatedesc',
    imgSource: '/img/illustration/v2/spot/change-date.png',
    event: '',
    analytic: '',
    params: '',
  },
  guest_data: {
    title: 'manage.changeguestdata',
    subtitle: 'hotel.changeguestdatadesc',
    imgSource: '/img/illustration/v2/spot/change-data.png',
    event: '',
    analytic: '',
    params: '',
  },
} as OrderBox;

const manageOrderItem = computed(() => manageOrderList[props.code]!);

const onClickManageOrder = (event: string | undefined, analytic: string | undefined, param: string | undefined) => {
  if (event) {
    jitsuEvent('user-click-button', {
      event_name: event,
      object_name: 'manage-booking',
      object_parameter: param ?? '',
    });
  }

  if (analytic) {
    gtagEvent(analytic, {});
  }
};
</script>

<template>
  <NuxtLink
    :to="props.path"
    :class="[
      'bg-white flex items-center justify-between rounded shadow-md w-fit cursor-pointer transition-all duration-200 hover:drop-shadow-xl',
      isMobile ? 'p-15' : 'p-20',
    ]"
    @click="onClickManageOrder(manageOrderItem.event, manageOrderItem.analytic, manageOrderItem.params)"
  >
    <div :class="['order-text flex items-center', isMobile ? 'gap-x-10' : 'gap-x-15']">
      <NuxtImg
        :src="manageOrderItem.imgSource"
        :alt="$t(manageOrderItem.title)"
        :width="isMobile ? 25 : 40"
        :height="isMobile ? 25 : 40"
        format="webp"
        loading="lazy"
        sizes="sm:50px lg:80px"
      />

      <div class="flex flex-col whitespace-nowrap gap-y-3 mr-20">
        <h3
          :class="{ 'text-small': props.isMobile }"
          class="font-bold text-gray-darkest text-left"
        >
          {{ $t(manageOrderItem.title) }}
        </h3>
        <p
          v-if="!isMobile"
          class="text-gray-dark text-left text-small"
        >
          {{ $t(manageOrderItem.subtitle) }}
        </p>
      </div>
    </div>

    <div class="w-fit ml-20">
      <ApzIcon
        :icon="['fas', 'chevron-right']"
        class="text-gray-darkest"
      />
    </div>
  </NuxtLink>
</template>
